import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Link,
  Spacer,
  Text,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useSWRConfig } from 'swr';
import FormField from 'design-system/molecules/form-field';
import {
  ROLE_ADMIN_USER,
  ROLE_PRE_AUTH_ONBOARD_NO_FACTOR,
  ROLE_PRE_AUTH_USER,
  ROLE_PRE_AUTH_USER_NO_FACTOR,
  ROLE_READ_USER,
  ROLE_STORMPATH_USER,
} from '../../constants/roles';
import { getCustomFingerprint } from '../../utils/browserUtils';
import { emailValidator } from '../../utils/email';
import { login } from '../../api/auth';
import { pathForgotPassword } from '../../constants/path';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { useFailureToast } from '../../hooks/useFailureToast';
import { useRedirectAfterLogin } from '../../hooks/useRedirectAfterLogin';
import MoovenLogo from '../../svg/mooven-logo.svg?react';

interface FormValues {
  username: string;
  password: string;
}

export default function Login({
  skipIntercom = false,
}: {
  skipIntercom?: boolean;
}) {
  const { track } = useAnalytics();
  const failureToast = useFailureToast();
  const { formatMessage } = useIntl();
  const redirectAfterLogin = useRedirectAfterLogin();
  const navigate = useNavigate();
  const { boot, shutdown } = useIntercom();
  const { mutate } = useSWRConfig();

  const {
    register,
    setError,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = async (values: FormValues) => {
    try {
      // clear cache to clear any leftover errors
      await mutate(() => true, undefined, { revalidate: false });
      const fingerprint = getCustomFingerprint(
        values.username,
        values.password
      );
      const response = await login(
        values.username,
        values.password,
        fingerprint
      );

      const roles = response.roles || [];

      if (response.authenticated) {
        // At least one role
        if (roles.length > 0) {
          shutdown();
          track('Login Succeeded', {
            referrer: 'Login Page',
            email: values.username,
            roles,
          });
          localStorage.setItem('roles', roles.join(','));
          localStorage.setItem(
            'isAdmin',
            roles.includes(ROLE_ADMIN_USER) ? '1' : '0'
          );
          if (roles.includes(ROLE_PRE_AUTH_USER_NO_FACTOR)) {
            navigate('/mfa/enroll', {
              state: { redirectAfterLogin },
            });
          } else if (roles.includes(ROLE_PRE_AUTH_USER)) {
            localStorage.setItem('isLoggedIn', '1');
            localStorage.setItem('currentRole', ROLE_PRE_AUTH_USER);
            localStorage.setItem('fingerprint', fingerprint.toString());
            track('MFA Code Required', { referrer: 'Login' });
            navigate('/mfa', {
              state: { redirectAfterLogin },
            });
          } else if (roles.includes(ROLE_STORMPATH_USER)) {
            localStorage.setItem('isLoggedIn', '1');
            localStorage.setItem('currentRole', ROLE_STORMPATH_USER);
            if (redirectAfterLogin.pathname === '/') {
              navigate('/dashboard');
            } else {
              navigate(redirectAfterLogin);
            }
          } else if (roles.includes(ROLE_READ_USER)) {
            localStorage.setItem('isLoggedIn', '1');
            localStorage.setItem('currentRole', ROLE_READ_USER);
            if (!redirectAfterLogin || redirectAfterLogin.pathname === '/') {
              navigate('/dashboard');
            } else {
              navigate(redirectAfterLogin);
            }
          } else if (roles.includes(ROLE_PRE_AUTH_ONBOARD_NO_FACTOR)) {
            localStorage.setItem('isLoggedIn', '1');
            localStorage.setItem(
              'currentRole',
              ROLE_PRE_AUTH_ONBOARD_NO_FACTOR
            );
            navigate('/onboarding', {
              state: { redirectAfterLogin, fromLogin: true },
            });
          }
        } else {
          // No roles
          failureToast({
            title: formatMessage({
              defaultMessage:
                'There has been a problem with your account. Please contact us.',
              id: 'Uv8oLL',
              description: 'Login failed error response message',
            }),
          });
        }
      }

      switch (response.status) {
        case 401: {
          setError('username', { type: 'custom' });
          setError('password', { type: 'custom' });
          track('Login Failed', {
            referrer: 'Login Page',
            error: '401 - Invalid Username or Password',
          });
          failureToast({
            title: formatMessage({
              defaultMessage: 'Invalid Username or Password',
              id: '6xZANp',
              description: 'Invalid username or password message',
            }),
          });
          break;
        }

        default: {
          break;
        }
      }

      if (response.error) {
        track('Login Failed', {
          referrer: 'Login Page',
          error: response.error,
        });
        failureToast({
          title: formatMessage({
            defaultMessage:
              'There has been a problem with your account. Please contact us.',
            id: 'Uv8oLL',
            description: 'Login failed error response message',
          }),
        });
      }
    } catch (e: any) {
      track('Login Failed', {
        referrer: 'Login Page',
        error: e?.message || e,
      });
      failureToast({
        title: formatMessage({
          defaultMessage:
            'Something went wrong. Please try again or contact us.',
          id: 'r++Sqe',
          description: 'Login failed error fallback message',
        }),
      });
    }
  };
  if (!skipIntercom) {
    boot();
  }
  return (
    <Flex w="100wh" h="100vh" bgColor="white" justify="center" align="center">
      <Box width={{ sm: '768px' }} m={8}>
        <Box w="150px" h="33px">
          <MoovenLogo />
        </Box>
        <Heading size="4xl" mb={4}>
          <FormattedMessage
            defaultMessage="Welcome back!"
            id="gixQEr"
            description="Login to Mooven header"
          />
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={10}>
            <Text fontSize="2xl">
              <FormattedMessage
                defaultMessage="Get started by entering your email address and password"
                id="19dU7K"
                description="Login to Mooven instructions"
              />
            </Text>
          </Box>
          <FormField
            mb={4}
            isInvalid={!!errors.username}
            errorText={errors.username?.message}
          >
            <Input
              focusBorderColor="green.500"
              type="text"
              placeholder="Email"
              data-testid="username"
              aria-label="username"
              {...register('username', {
                required: formatMessage({
                  defaultMessage: 'Please enter your email',
                  id: 'sCjYcA',
                  description: 'Error message for username',
                }),
                validate: {
                  maxLength: (value) =>
                    value.length <= 50 ||
                    formatMessage({
                      defaultMessage:
                        'The email should have at most 50 characters',
                      id: 'E5R55X',
                    }),
                  matchPattern: (value) =>
                    emailValidator([value]) ||
                    formatMessage({
                      defaultMessage: 'Must be a valid email address',
                      id: '45Igqv',
                    }),
                },
              })}
            />
          </FormField>
          <FormField
            mb={10}
            isInvalid={!!errors.password}
            errorText={errors.password?.message}
          >
            <Input
              focusBorderColor="green.500"
              type="password"
              autoComplete="current-password"
              id="current-password"
              data-testid="password"
              placeholder="Password"
              aria-label="password"
              {...register('password', {
                required: formatMessage({
                  defaultMessage: 'Please enter password',
                  id: 'TIrT2w',
                  description: 'Error message for password',
                }),
              })}
            />
          </FormField>
          <HStack mb={10} spacing={4}>
            <Button
              isLoading={isSubmitting}
              data-testid="login-btn"
              variant="solid"
              colorScheme="brand"
              type="submit"
              isDisabled={!isValid || isSubmitting}
            >
              <FormattedMessage
                defaultMessage="Login"
                id="0uNSB7"
                description="Login action"
              />
            </Button>
            <Link as={RouterLink} to={pathForgotPassword()}>
              <FormattedMessage
                defaultMessage="Forgot password?"
                id="y06aop"
                description="Forgot password action"
              />
            </Link>
          </HStack>
          <Flex>
            <Spacer />
            <Text fontSize="sm">
              <FormattedMessage
                defaultMessage="Don't have an account yet? <l>Get in touch</l>"
                id="lpGy2Q"
                description="Login to contact us action"
                values={{
                  l: (it) => (
                    <Link
                      href="mailto:rkrctuz0@mooven.intercom-mail.com"
                      id="intercom-activator"
                    >
                      {it}
                    </Link>
                  ),
                }}
              />
            </Text>
          </Flex>
        </form>
      </Box>
    </Flex>
  );
}
