import {
  createBrowserRouter,
  createRoutesFromElements,
  Link,
  Navigate,
  Route,
  Outlet,
} from 'react-router-dom';
import { Box, Flex, Heading, Spinner } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { IntercomProvider } from 'react-use-intercom';
import { lazy, Suspense } from 'react';
import {
  ForgotPassword,
  Login,
  ResetPassword,
  VerifyMFA,
} from '../../components/Auth';
import LayoutAuthenticated, {
  AuthenticatedOrgContext,
  AuthenticatedUserLayoutContext,
} from '../../components/LayoutAuthenticated/LayoutAuthenticated';
import {
  pathCookies,
  pathDashboard,
  pathForbidden,
  pathForgotPassword,
  pathLogin,
  pathLogout,
  pathMfaLogin,
  pathOnboarding,
  pathResetPassword,
  pathSettings,
  pathSites,
  pathSitesEdit,
  pathSitesManage,
  pathSitesOverviewLegacy,
  pathSitesOverview,
  pathSitesPlan,
  pathSitesPlanLegacy,
  pathSitesView,
  PlanInsightType,
  pathTeamSettings,
  pathSitesCreate,
  pathRouteStatus,
  pathUnsubscribe,
  pathSiteVmsBoards,
  pathSiteVmsBoard,
  pathRoutePollStatus,
  pathImpactAssessmentRequest,
} from '../../constants/path';
import useParamInsightId from '../../hooks/useParamInsightId';

import ErrorPage from '../../components/ErrorPage';
import Logout from '../../components/Auth/Logout';
import CheckAuth from '../../components/Auth/CheckAuth';
import {
  AnalyticsPageViewTracker,
  UserAnalyticsProvider,
} from '../../hooks/analytics/useAnalytics';
import RouteErrorBoundary from '../../components/RouteErrorBoundary';
import { envConfig, getEnvironment } from '../../config/envConfig';
import { LayoutSmartVmsPageWrapper } from '../../components/SmartVms/LayoutSmartVms';
import { SiteContextProvider } from '../../hooks/useSiteView';
import SiteUserViewDefaultSelector from '../../components/SiteView/SiteUserViewDefaultSelector';
import { WithProfiler } from './with-profiler';

const Onboarding = lazy(() => import('../../components/Onboarding'));
const PlanVehicleCount = lazy(
  () => import('../../components/PlanVehicleCount')
);
const Unsubscribe = lazy(() => import('../../components/Unsubscribe'));
const ImpactAssessmentRequest = lazy(
  () => import('../../components/ImpactAssessmentRequest')
);
const RoutePollingStatus = lazy(
  () => import('../../components/RoutePollingStatus')
);
const SiteView = lazy(() => import('../../components/SiteView'));
const Plan = lazy(() => import('../../components/SitePlan'));
const SiteCreate = lazy(() => import('../../components/SiteCreate'));
const EditSite = lazy(() => import('../../components/SiteEdit/EditSite'));
const EditableSites = lazy(() => import('../../components/EditableSites'));
const HomePage = lazy(() => import('../../components/HomePage'));
const TeamSettings = lazy(() => import('../../components/TeamSettings'));
const UserProfile = lazy(() => import('../../components/UserProfile'));
const SmartVms = lazy(() => import('../../components/SmartVms'));
const SmartVmsCreateBoard = lazy(
  () => import('../../components/SmartVms/SmartVmsCreateBoard')
);
const PlanningTaskList = lazy(
  () => import('../../pages/programme-view/PlanningTaskList')
);
const ProjectsListPage = lazy(
  () => import('../../pages/programme-view/pages/ProjectsListPage')
);
const ProjectPage = lazy(
  () => import('../../pages/programme-view/pages/ProjectPage')
);

export default createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        getEnvironment() !== 'production' ? (
          <WithProfiler profilerId="root" />
        ) : (
          <Outlet />
        )
      }
    >
      <Route element={<AppWrappers />} errorElement={<RouteErrorBoundary />}>
        <Route path="/" element={<Navigate to={pathDashboard()} replace />} />
        <Route
          path={pathCookies()}
          element={
            <ErrorPage
              text="Please enable cookies to view this website"
              noLink
            />
          }
        />
        <Route
          path={pathForbidden()}
          element={<ErrorPage text="403 Forbidden" />}
        />
        <Route path={pathOnboarding()} element={<Onboarding />} />
        <Route path={pathUnsubscribe()} element={<Unsubscribe />} />
        <Route path={pathRoutePollStatus()} element={<RoutePollingStatus />} />

        <Route element={<CheckAuth />}>
          <Route element={<PlanningTaskList />}>
            <Route
              path="/programme-view/:insightId/programme/:programmeId"
              element={<ProjectsListPage />}
            />
            <Route
              path="/programme-view/:insightId"
              element={<ProjectsListPage />}
            />
            <Route
              path="/programme-view/:insightId/programme/:programmeId/projects/:projectId"
              element={<ProjectPage />}
            />
          </Route>
          <Route path={pathRouteStatus()} element={<RoutePollingStatus />} />
          <Route path={pathLogin()} element={<Login />} />
          <Route path={pathForgotPassword()} element={<ForgotPassword />} />
          <Route path={pathResetPassword()} element={<ResetPassword />} />
          <Route path={pathMfaLogin()} element={<VerifyMFA />} />
          <Route path={pathLogout()} element={<Logout />} />

          <Route element={<AuthenticatedUserLayoutContext />}>
            <Route element={<AuthenticatedOrgContext />}>
              <Route path={pathDashboard()} />
              <Route
                path={pathSiteVmsBoards()}
                element={<LayoutSmartVmsPageWrapper Component={SmartVms} />}
              />
              <Route
                path={pathSiteVmsBoard()}
                element={
                  <LayoutSmartVmsPageWrapper Component={SmartVmsCreateBoard} />
                }
              />

              <Route
                path={pathImpactAssessmentRequest()}
                Component={ImpactAssessmentRequest}
              />

              <Route element={<LayoutAuthenticated showProjectSelector />}>
                <Route path={pathTeamSettings()} element={<TeamSettings />} />
                <Route
                  path={pathSitesOverviewLegacy()}
                  element={
                    <RedirectWithInsightId
                      pathBuilder={(insightId) =>
                        pathSitesOverview(insightId, 'sites', null)
                      }
                    />
                  }
                />

                <Route path={pathSitesOverview()} element={<HomePage />} />
                <Route path={pathSitesManage()} element={<EditableSites />} />
              </Route>

              <Route
                element={<LayoutAuthenticated showProjectSelector={false} />}
              >
                <Route path={pathSitesCreate()} element={<SiteCreate />} />
                <Route
                  path={pathSites()}
                  element={
                    <RedirectWithInsightId
                      pathBuilder={pathSitesOverviewLegacy}
                    />
                  }
                />
                <Route
                  element={
                    <SiteContextProvider>
                      <SiteUserViewDefaultSelector>
                        <Outlet />
                      </SiteUserViewDefaultSelector>
                    </SiteContextProvider>
                  }
                >
                  <Route path={pathSitesEdit()} element={<EditSite />} />
                  <Route path={pathSitesView()} element={<SiteView />} />
                  <Route path={pathSitesPlan()} element={<Plan />} />
                  <Route
                    path={pathSitesPlan(undefined, PlanInsightType.COUNT)}
                    element={<PlanVehicleCount />}
                  />
                  <Route
                    path={pathSitesPlanLegacy()}
                    element={
                      <RedirectWithInsightId
                        pathBuilder={(insightId) =>
                          pathSitesPlan(insightId, PlanInsightType.TIME)
                        }
                      />
                    }
                  />
                </Route>
              </Route>
            </Route>

            <Route
              element={<LayoutAuthenticated showProjectSelector={false} />}
            >
              <Route path={pathSettings()} element={<UserProfile />} />
              <Route
                path={'/*'}
                element={
                  <Box textAlign="center" px={12} py={20}>
                    <Heading>
                      <FormattedMessage
                        defaultMessage="404 not found"
                        id="Cu34ak"
                        description="Page not found message"
                      />
                    </Heading>
                    <Link to="/">
                      <FormattedMessage
                        defaultMessage="Go to homepage"
                        id="bkWXbu"
                        description="link to homepage from error page"
                      />
                    </Link>
                  </Box>
                }
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

function AppWrappers() {
  return (
    <IntercomProvider appId={envConfig.INTERCOM_APP}>
      <UserAnalyticsProvider>
        <AnalyticsPageViewTracker />
        <Suspense
          fallback={
            <Flex
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              p={10}
            >
              <Spinner variant="mooven" size="xl" />
            </Flex>
          }
        >
          <Outlet />
        </Suspense>
      </UserAnalyticsProvider>
    </IntercomProvider>
  );
}

function RedirectWithInsightId({
  pathBuilder,
}: {
  pathBuilder: (insightId: string) => string;
}) {
  const insightId = useParamInsightId();
  return <Navigate to={pathBuilder(insightId)} replace />;
}
