import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Input,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import FormField from 'design-system/molecules/form-field';
import { ROLE_READ_USER, ROLE_STORMPATH_USER } from '../../constants/roles';
import { requestNewMFACode, submitMFA } from '../../api/auth';
import { pathLogin } from '../../constants/path';
import { useFailureToast } from '../../hooks/useFailureToast';
import usePersistentCountdown from '../../hooks/usePersistentCountdown';
import { useRedirectAfterLogin } from '../../hooks/useRedirectAfterLogin';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import MoovenLogo from '../../svg/mooven-logo.svg?react';
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { errorReport } from '../../utils/errors';

interface FormValues {
  mfaCode: string;
  rememberUser: boolean;
}

export default function VerifyMFA() {
  const { track } = useAnalytics();
  const failureToast = useFailureToast();
  const successToast = useSuccessToast();
  const { formatMessage } = useIntl();
  const redirectAfterLogin = useRedirectAfterLogin();
  const [timeRemaining, setTimeRemaining] = usePersistentCountdown(
    'mv-login-mfa-code-time-remaining',
    60
  );
  const navigate = useNavigate();
  const {
    register,
    setError,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      mfaCode: '',
      rememberUser: false,
    },
  });

  const handleCancel = () => {
    navigate(pathLogin(), { state: redirectAfterLogin });
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const fingerprint = values.rememberUser
        ? localStorage.getItem('fingerprint')
        : null;

      // Clear fingerprint from localstorage
      localStorage.removeItem('fingerprint');

      const response = await submitMFA(values.mfaCode, fingerprint);
      const roles = response.roles || [];
      if (response.authenticated) {
        track('MFA Code Verified', { referrer: 'Login' });
        localStorage.setItem('roles', roles.join(','));
        if (
          roles.includes(ROLE_STORMPATH_USER) ||
          roles.includes(ROLE_READ_USER)
        ) {
          if (roles.includes(ROLE_STORMPATH_USER)) {
            localStorage.setItem('currentRole', ROLE_STORMPATH_USER);
          } else if (roles.includes(ROLE_READ_USER)) {
            localStorage.setItem('currentRole', ROLE_READ_USER);
          }

          if (
            redirectAfterLogin.pathname === '/' ||
            redirectAfterLogin.pathname === '/mfa' ||
            redirectAfterLogin.pathname === '/login'
          ) {
            navigate('/dashboard');
          } else {
            navigate(redirectAfterLogin);
          }
        }
      } else {
        localStorage.setItem('roles', '');
        localStorage.setItem('currentRole', '');
        if (response.status === 401) {
          failureToast({
            title: formatMessage({
              defaultMessage: 'Incorrect code. Please try again.',
              id: 'gbOfDc',
              description: 'Incorrect code message',
            }),
          });
          track('Incorrect Code Entered', { referrer: 'Login' });
          setError(
            'mfaCode',
            {
              type: 'custom',
              message: formatMessage({
                defaultMessage: 'Incorrect code',
                id: 'iIfKGY',
                description: 'Incorrect code input validation message',
              }),
            },
            { shouldFocus: true }
          );
        } else {
          failureToast({
            title: formatMessage({
              defaultMessage:
                'We were unable to authenticate you, please try again or contact us.',
              id: 'k4iJlW',
              description: 'Failed to authenticate MFA code message',
            }),
          });
          errorReport.critical({
            message: 'MFA verification failed',
            response,
          });
          setError('mfaCode', { type: 'custom' }, { shouldFocus: true });
        }
      }
    } catch (e) {
      // Handle errors
      localStorage.setItem('roles', '');
      localStorage.setItem('currentRole', '');
      failureToast({
        title: formatMessage({
          defaultMessage:
            'We were unable to verify your code, please try again or contact us.',
          id: 'E6sYg1',
          description: 'Failed to verify MFA code message',
        }),
      });
      setError('mfaCode', { type: 'custom' }, { shouldFocus: true });
    }
  };

  const onRequestNewCode = async () => {
    track('MFA Code Requested', {
      referrer: 'Login',
      deviceType: 'SMS',
    });
    try {
      await requestNewMFACode();
      successToast({
        title: formatMessage({
          defaultMessage:
            'We have sent a new unique code via SMS to your registered mobile phone number. Contact us if you do not receive it.',
          id: 'j+0KB1',
          description: 'Successfully requested new MFA code message',
        }),
      });
      setTimeRemaining(60);
    } catch (e) {
      errorReport.critical(e, { referrer: 'Failed to request a new MFA code' });
      failureToast({
        title: formatMessage({
          defaultMessage: 'Failed to request a new code. Please try again.',
          id: 'UVT9/Z',
          description: 'Failure to request new MFA code message',
        }),
      });
    }
  };

  return (
    <Flex w="100wh" h="100vh" bgColor="white" justify="center" align="center">
      <Box width={{ sm: '768px' }} m={8}>
        <Box w="150px" h="33px">
          <MoovenLogo />
        </Box>
        <Heading size="4xl" mb={4}>
          <FormattedMessage
            defaultMessage="Verify your identity"
            id="HDipxT"
            description="Verify MFA header"
          />
        </Heading>
        <Box mb={10}>
          <Text fontSize="2xl">
            <FormattedMessage
              defaultMessage="A unique code has been sent via SMS to your mobile phone."
              id="rfqEF0"
              description="MFA code sent to phone number"
            />
          </Text>
        </Box>
        <Box mb={4} as="form" onSubmit={handleSubmit(onSubmit)}>
          <FormField
            mb={4}
            isInvalid={!!errors.mfaCode}
            errorText={errors.mfaCode?.message}
          >
            <Input
              type="number"
              autoComplete="one-time-code"
              aria-label="one-time-code"
              placeholder={formatMessage({
                description: 'MFA code input placeholder',
                defaultMessage: 'SMS Code',
                id: 'x80KsF',
              })}
              inputMode="numeric"
              pattern="[0-9]*"
              {...register('mfaCode', {
                minLength: {
                  value: 6,
                  message: formatMessage({
                    defaultMessage: 'Code must be 6 characters in length',
                    id: 'nBeon2',
                    description: 'MFA code length message',
                  }),
                },
                required: formatMessage({
                  defaultMessage: 'Please enter the one-time code',
                  id: 'Qdf6AR',
                  description: 'Error message for MFA code',
                }),
              })}
            />
          </FormField>
          <FormField mb={8}>
            <Checkbox
              aria-label="remember-user"
              colorScheme="green"
              {...register('rememberUser')}
            >
              Don&apos;t ask again on this computer
            </Checkbox>
          </FormField>

          <HStack spacing={4}>
            <Button
              isLoading={isSubmitting}
              variant="solid"
              colorScheme="greenDark"
              type="submit"
              isDisabled={!isValid || isSubmitting}
            >
              <FormattedMessage
                defaultMessage="Submit"
                id="Oep6va"
                description="Submit button"
              />
            </Button>
            <Button variant="ghost" onClick={handleCancel}>
              <FormattedMessage
                defaultMessage="Cancel"
                id="+ccQqf"
                description="Return to login action"
              />
            </Button>
          </HStack>
        </Box>
        <Box data-ignore-visual-test>
          <Text color="gray.500" mb={2}>
            {timeRemaining > 0 ? (
              <FormattedMessage
                defaultMessage="The code has been sent. In {timeRemaining} seconds you can request a new code."
                id="YBYALZ"
                description="Confirm MFA code status"
                values={{ timeRemaining }}
              />
            ) : (
              <Button
                variant="link"
                colorScheme="green"
                onClick={onRequestNewCode}
              >
                <FormattedMessage
                  defaultMessage="Request new code"
                  id="+9OJyx"
                  description="Confirm MFA code request new code action"
                />
              </Button>
            )}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
}
