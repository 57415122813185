import { Profiler, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

export function WithProfiler({
  profilerId,
}: {
  profilerId: string;
}): ReactNode {
  return (
    <Profiler
      id={profilerId}
      onRender={(id, _phase, actualDuration) => {
        (window as any).profiler = (window as any).profiler || {};
        (window as any).profiler[id] = (window as any).profiler[id] || {};
        (window as any).profiler[id].renderTime =
          (window as any).profiler[id].renderTime || 0;
        (window as any).profiler[id].renderTime =
          (window as any).profiler[id].renderTime + actualDuration;
        (window as any).profiler[id].renderCount =
          (window as any).profiler[id].renderCount || 0;
        (window as any).profiler[id].renderCount =
          (window as any).profiler[id].renderCount + 1;
      }}
    >
      <Outlet />
    </Profiler>
  );
}
