import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useRouteError } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { errorReport } from '../utils/errors';
import { MESSAGE_GENERIC_ERROR } from '../constants/messages';

export default function RouteErrorBoundary() {
  const { formatMessage } = useIntl();
  const error = useRouteError();
  useEffect(() => {
    handleLazyLoadError(error);
    errorReport.unknown(error);
  }, [error]);
  return (
    <Box data-testid="error-block" px={12} py={20} textAlign="center">
      {formatMessage(MESSAGE_GENERIC_ERROR)}
    </Box>
  );
}

function handleLazyLoadError(error) {
  if (
    error instanceof Error &&
    error.message.includes('Failed to fetch dynamically imported module') &&
    error.message.includes('mooven-chunk') &&
    localStorage.getItem('refreshKey') !== error.message
  ) {
    // save file reference to localStorage to avoid infinite loop
    localStorage.setItem('refreshKey', error.message);
    errorReport.log(error);
    window.location.reload();
  }
}
